import Axios from 'axios';
import server from 'util/Server';
import Storage from 'util/store/Storage';
import { sessionCaducada } from 'util/funciones'


export const generateExcel = async (body: any, url?: string) => {
  let DEFAULT_URL = `${server.host || ''}${server.puertoMovimiento || ''}/api/v2/venta_detallada_excel`

  DEFAULT_URL = url || DEFAULT_URL

  return Axios.post(DEFAULT_URL, body,{headers: { Authorization: server.obtenerToken() }})
}
export const generateCsv = async (body: any) => {
  return Axios.post((server.host || '') + (server.puertoMovimiento || '') + '/api/v2/export_csv', body, {headers: { Authorization: server.obtenerToken() }})
}
export const generatePdf = async (body: any) => {
  return Axios.post((server.host || '') + (server.puertoMovimiento || '') + '/api/v2/venta_detallada_pdf', body, {headers: { Authorization: server.obtenerToken() }})
}

export const descargarExcel = async (body: any) => {
  return Axios.post((server.host || '') + (server.puertoMovimiento || '') + '/api/v2/descargar_reportes', body, {headers: { Authorization: server.obtenerToken() }})
}



