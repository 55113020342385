import { Paper } from '@material-ui/core'
import React, { useEffect, useMemo, useState } from 'react'
import { App, Button, Checkbox, DatePicker, Input, Select, TimePicker } from 'antd';
import { OptionProps } from 'antd/es/select';
import './index.css'
import { filterOptions } from 'util/funcionesNews';
import dayjs from 'dayjs';
import { format } from 'path';

const { RangePicker } = DatePicker;

interface FiltrosProps {

    showTime?: boolean;
    formatInputRangePicker?:'YYYY-MM-DD'|'DD-MM-YYYY'|'YYYY/MM/DD'|'DD/MM/YYYY'
    checkA?: boolean
    labelCheckA?: string
    timePickerA?: boolean
    timePickerB?: boolean
    rangePicker?: boolean
    maxDaysDifferences?: number
    selectA?: boolean
    selectB?: boolean
    selectC?: boolean
    inputA?: boolean
    placeholderInputA?: string

    isMultiSelectSelectA?: boolean
    isMultiSelectSelectB?: boolean
    isMultiSelectSelectC?: boolean
    placeholderSelectA?: string
    placeholderSelectB?: string
    placeholderSelectC?: string
    placeholderTimePickerA?: string
    placeholderTimePickerB?: string
    optionsSelectA?: Omit<OptionProps, 'children'>[]
    optionsSelectB?: Omit<OptionProps, 'children'>[]
    optionsSelectC?: Omit<OptionProps, 'children'>[]
    onChangeCheckA?: (values: any) => void
    onChangeRangePicker?: (values: any) => void
    onChangeSelectA?: (value: any) => void
    onChangeSelectB?: (value: any) => void
    onChangeSelectC?: (value: any) => void
    onChangeInputA?: (values: any) => void
    onChangeTimePickerA?: (values: any) => void
    onChangeTimePickerB?: (values: any) => void


    onClickButton?: () => void
    textButton?: string
    loadingButton?: boolean

    formatDateRange?: 'YYYY-MM-DD' | 'YYYY-MM-DD HH:mm:ss' | 'YYYY-MM-DD hh:mm A' | 'YYYY-MM-DD HH:mm' | 'YYYY-MM-DDTHH:mm',
    formatInputTimePicker?: 'HH:mm:ss' | 'hh:mm A' | 'HH:mm A'
    formatTimePickerOutput?: 'HH:mm:ss' | 'hh:mm A' | 'HH:mm A' | 'HH:mm'
    validateTimePickerAB?: boolean
    noShadows?: boolean
    datePicker?: boolean
    placeholderDatePicker?:string
    onChangeDatePicker?:(value:any) => void


}

interface Date {
    endDate: dayjs.Dayjs | null;
    startDate: dayjs.Dayjs | null
}

const FiltrosFC = React.forwardRef((props: FiltrosProps, ref) => {
    const {notification} = App.useApp()
    const {
        formatInputRangePicker='YYYY-MM-DD',
        showTime = false,
        checkA = false,
        labelCheckA = '',
        
        rangePicker,
        timePickerA = false,
        timePickerB = false,
        selectA,
        selectB,
        selectC,
        inputA,
        isMultiSelectSelectA = false,
        isMultiSelectSelectB = false,
        isMultiSelectSelectC = false,
        formatInputTimePicker = 'hh:mm A',
        formatTimePickerOutput,
        onChangeCheckA,
        onChangeRangePicker,
        onChangeSelectA,
        onChangeSelectB,
        onChangeSelectC,
        onChangeTimePickerA,
        onChangeTimePickerB,
        onChangeInputA,
        onChangeDatePicker,
        optionsSelectA = [],
        optionsSelectB = [],
        optionsSelectC = [],
        placeholderSelectA,
        placeholderSelectB,
        placeholderSelectC,
        placeholderInputA,
        placeholderTimePickerA,
        placeholderTimePickerB,
        onClickButton,
        maxDaysDifferences = 90,
        validateTimePickerAB = false,
        textButton = 'Buscar',
        loadingButton = false,
        formatDateRange='YYYY-MM-DD',
        noShadows,
        datePicker=false,
        placeholderDatePicker
    } = props

    const [rangeDates, setRangeDates] = useState<Date>({
        startDate: null,
        endDate: null,
    })
    const [checksValue, setChecksValue] = useState<any>({
        'A':false
    })
    const [timePickerValues, setTimePickerValues] = useState<any>({
        A: null,
        B: null,
        C: null,
    })
    const [rangeDatesValue, setRangeDatesValue] = useState<any>([])
    const [setselectValues, setSetselectValues] = useState({
        A: isMultiSelectSelectA ? [] : null,
        B: isMultiSelectSelectB ? [] : null,
        C: isMultiSelectSelectC ? [] : null
    })
    const [blockTime, setBlockTime] = useState(false)
    const handlerChecks = (name:'A',value:any) =>{
        setChecksValue((prev:any)=>({...prev,[name]:value}))
    }
    const handlerSelectsValues = (name: 'A' | 'B' | 'C', value: any) => {
        setSetselectValues((prev) => ({ ...prev, [name]: value }))
    }
    const handlerTimePickerValue = (name: 'A' | 'B', value: any) => {
        setTimePickerValues((prev: any) => ({ ...prev, [name]: value }))
    }
    const disabledFutureDates = (startDate: any) => (current: any) => {
        const today = new Date();
        return current && (current.valueOf() > today.valueOf() || current.valueOf() < startDate.valueOf());
    };

    const monthsDiff = useMemo(() => {
        if (!rangeDates.startDate || !rangeDates.endDate) return 0
        return rangeDates.endDate?.diff(rangeDates.startDate, 'days')
    }, [rangeDates])

    const resetSelectA = () => handlerSelectsValues('A', null)
    const resetSelectB = () => handlerSelectsValues('B', null)
    const resetSelectC = () => handlerSelectsValues('C', null)
    const resetCheckA = () => handlerChecks('A', false)
    const resetTimePickerA = () => handlerTimePickerValue('A', null)
    const resetTimePickerB = () => handlerTimePickerValue('B', null)

    React.useImperativeHandle(ref, () => ({
        resetSelectB,
        resetSelectA,
        resetSelectC,
        resetCheckA,
        resetTimePickerA,
        resetTimePickerB
    }));

    useEffect(() => {
        if (validateTimePickerAB && timePickerValues.A && timePickerValues.B) {
            if (timePickerValues.B.isBefore(timePickerValues.A) && monthsDiff == 0) {
                notification.warning({
                    message: 'Atención',
                    description: 'Hora fin no puede ser menor o igual que hora inicial'
                })
                setBlockTime(true)
            }else{
                setBlockTime(false)
            }
        }
    }, [timePickerValues, monthsDiff])



    useEffect(() => {
        if (rangePicker && monthsDiff >= maxDaysDifferences) {
            notification.warning({
                message: 'Rango de fechas invalido',
                description: 'Por favor, seleccione un rango de fechas que esté dentro del rango permitido.',
                placement: 'top'
            })
        }
    }, [monthsDiff])

    useEffect(() => {
        handlerTimePickerValue('A',null)
        handlerTimePickerValue('B',null)
    }, [timePickerB,timePickerA])
    
    useEffect(() => {
    if(!checkA){
        handlerChecks('A',false)
    }
    }, [checkA])
    


    return (
        <Paper className={`paper-filter ${noShadows ? 'noshadow' : ''}`}>
            <div className={`grid-filters`}>
                {
                    datePicker && 
                    <DatePicker 
                    placeholder={placeholderDatePicker}
                    onChange={(e)=>{
                        if(onChangeDatePicker){
                            onChangeDatePicker(e?.format(formatDateRange));
                        }
                    }}
                    
                    />
                }
                {rangePicker &&

                    <RangePicker
                        status={monthsDiff >= maxDaysDifferences ? 'warning' : undefined}
                        className='select_filter'

                        disabledDate={disabledFutureDates('2024-01-01')}
                        inputReadOnly
                        value={rangeDatesValue}
                        showTime={showTime ? {
                            showHour: true,
                            showMinute: true,
                            format: 'hh:mm A', // hh para horas en formato de 12 horas, mm para minutos, A para AM/PM
                            use12Hours: true, // Habilita el uso de formato de 12 horas
                        } : false}
                        allowClear={false}
                        onChange={(e) => {
                            if (!e) return
                            if (onChangeRangePicker) {
                                if (formatDateRange) {
                                    if (showTime) {
                                        onChangeRangePicker([e[0]?.format(formatDateRange), e[1]?.format(formatDateRange)])

                                    } else {
                                        onChangeRangePicker([e[0]?.startOf('day')?.format(formatDateRange), e[1]?.endOf('day')?.format(formatDateRange)])

                                    }
                                } else {
                                    onChangeRangePicker(e)

                                }
                            }
                            setRangeDates(() => ({
                                endDate: e[1],
                                startDate: e[0]
                            }))
                            setRangeDatesValue(e)
                        }}
                        format={showTime ? 'YYYY/MM/DD hh:mm A' :formatInputRangePicker}

                    />
                }
                {selectA && <Select
                    className='select_filter'
                    mode={isMultiSelectSelectA ? 'multiple' : undefined}
                    options={optionsSelectA}
                    placeholder={placeholderSelectA}
                    value={setselectValues.A}
                    onChange={(e) => {
                        if (onChangeSelectA) {
                            onChangeSelectA(e)
                        }
                        handlerSelectsValues('A', e)
                    }}
                    filterOption={filterOptions}
                    showSearch
                />}
                {
                    checkA && <div className='checkWrapper'>
                        <Checkbox
                        checked={checksValue.A}

                        onChange={(e) => {
                            if (onChangeCheckA) {
                                onChangeCheckA(e.target.checked)
                            }
                            handlerChecks('A', e.target.checked)
                        }}>
                            {labelCheckA}
                        </Checkbox>
                    </div>
                }
                {
                    timePickerA && <TimePicker
                        allowClear={false}
                        onChange={(e) => {
                            if (!e) return
                            if (onChangeTimePickerA) {
                                if (formatTimePickerOutput) {
                                    onChangeTimePickerA(e.format(formatTimePickerOutput))
                                } else {
                                    onChangeTimePickerA(e)
                                }
                            }
                            handlerTimePickerValue('A', e)

                        }}
                        value={timePickerValues.A}
                        format={formatInputTimePicker}
                        placeholder={placeholderTimePickerA}

                    />
                }
                {
                    timePickerB && <TimePicker
                        allowClear={false}
                        format={formatInputTimePicker}
                        onChange={(e) => {
                            if (!e) return
                            if (onChangeTimePickerB) {
                                if (formatTimePickerOutput) {
                                    onChangeTimePickerB(e.format(formatTimePickerOutput))
                                } else {
                                    onChangeTimePickerB(e)
                                }
                            }
                            handlerTimePickerValue('B', e)
                        }}
                        value={timePickerValues.B}

                        placeholder={placeholderTimePickerB}

                    />
                }
                {selectB && <Select
                    className='select_filter'
                    mode={isMultiSelectSelectB ? 'multiple' : undefined}
                    value={setselectValues.B}
                    options={optionsSelectB}
                    placeholder={placeholderSelectB}
                    onChange={(e) => {
                        if (onChangeSelectB) {
                            onChangeSelectB(e)
                        }
                        handlerSelectsValues('B', e)
                    }}
                    filterOption={filterOptions}
                    showSearch
                    allowClear
                    onClear={() => {
                        if (onChangeSelectB) {
                            onChangeSelectB(null)
                        }
                        handlerSelectsValues('B', null)
                    }}
                />}
                 {selectC && <Select
                    className='select_filter'
                    mode={isMultiSelectSelectC ? 'multiple' : undefined}
                    value={setselectValues.C}
                    options={optionsSelectC}
                    placeholder={placeholderSelectC}
                    onChange={(e) => {
                        if (onChangeSelectC) {
                            onChangeSelectC(e)
                        }
                        handlerSelectsValues('C', e)
                    }}
                    filterOption={filterOptions}
                    showSearch
                    allowClear
                    onClear={() => {
                        if (onChangeSelectB) {
                            onChangeSelectB(null)
                        }
                        handlerSelectsValues('C', null)
                    }}
                />}

                {
                    inputA && <Input
                        className='fieldForm'
                        onChange={(e) => {
                            if (onChangeInputA) {
                                onChangeInputA(e.target.value)
                            }
                        }}
                        placeholder={placeholderInputA}
                    />
                }

                <Button
                    className='btn-search'
                    disabled={monthsDiff >= maxDaysDifferences || blockTime}
                    onClick={onClickButton}
                    loading={loadingButton}> {textButton}</Button>
            </div>
        </Paper>

    )
})

export default FiltrosFC
