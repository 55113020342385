import React, { useState, useEffect } from 'react';
import { Table, Input, Button, App } from 'antd';
import axios from 'axios';
import server from 'util/Server';
import excelIcon from '../../assets/images/excel.png'
import PdfIcon from '../../assets/images/pdf.png'
import { objetoVacio } from 'util/utils';
import { generateExcel, generatePdf } from './services/services';
import Storage from 'util/store/Storage'
import './index.css'
import { removeTotalPaginator, sessionCaducada, setearMiles } from 'util/funciones';
import { exportTablePDF } from 'util/funcionesNews';
import { saveAs } from 'file-saver';
import * as XLSX from "xlsx";
import jsPDF from 'jspdf';

const SmartDataTable = ({ header = undefined, searchKey = 'search', columns, autoLoad = false, nombre_reporte = 'Reporte', nullUpdate, exportPDFLocal = false, ...props }: any) => {
    enum MESSAGE_STATUS {
        UNAUTORIZE = 'Request failed with status code 401',
    }
    const { notification } = App.useApp()
    const { usuario } = Storage.getState()
    const [data, setData] = useState<any[]>([]);
    const [total, setTotal] = useState<boolean>(false);
    const [loaderExcel, setLoaderExcel] = useState(false)
    const [loaderPDF, setLoaderPDF] = useState(false)
    const [sort, setSort] = useState({})
    const [pagination, setPagination] = useState<any>({
        current: 1,
        pageSize: 20,
        showSizeChanger: false,
        simple: true,
        hideOnSinglePage: true
    });
    const [pageSize, setPageSize] = useState<number>(20);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>('');
    const [filterDefaultValues, setFilterDefaultValues] = useState<any>()

    const checkSkipByFetch = (newFetch: boolean) => !newFetch ? (currentPage - 1) * 20 : 0

    const closeSession = (error: any) => {
        if (error.message == MESSAGE_STATUS.UNAUTORIZE) {
            sessionCaducada()
        }
    }

    const fetchData = async (newFetch = false) => {
        try {
            if (!loading) {

                let query = {
                    ...filterDefaultValues,
                    ...props.filterTabla,
                    limit: 20,
                    skip: checkSkipByFetch(newFetch),
                }

                if (query?.search?.trim() == '') {
                    delete query.search
                }
                if (props.filterTabla.fecha_inicial == undefined || props.filterTabla.fecha_final == undefined || props.filterTabla.identificadorEstacion == undefined) {
                    return
                }
                props.hanldeLoading(true)
                setLoading(true);
                if (objetoVacio(sort)) {
                    delete query.sort
                }
                const { data, total, skip, limit }: any = await axios.get<any>(
                    `${props.apiUrl}`, { params: query, headers: { Authorization: server.obtenerToken() } }
                ).then(({ data }) => data)

                if (data?.length == 0) {
                    notification.info({ message: 'Sin información', description: 'No se encontraron datos entre las fechas seleccionadas', placement: 'bottomRight', duration: 5 })
                    setData([])
                    setLoading(false);
                } else {
                    setData(data);
                    setLoading(false);
                    if (newFetch) {
                        setPagination((prev: any) => ({
                            ...prev,
                            current: 1,
                        }))
                    }
                    setPagination((prev: any) => ({
                        ...prev,
                        total: 9999999
                    }));
                    removeTotalPaginator()
                }

            }
            nullUpdate()

        } catch (error: any) {
            closeSession(error)
            setLoading(false);
            nullUpdate()
            props.hanldeLoading(false)
            notification.error({ message: 'Ocurrió un error', description: 'No se pudo realizar la consulta, por favor intente mas tarde', placement: 'bottomRight', duration: 5 })
            console.error(error);
        } finally {
            props.hanldeLoading(false)
        }
    };

    const handleSearch = () => {
        setFilterDefaultValues({
            ...filterDefaultValues,
            [searchKey]: searchText
        });
        setCurrentPage(1);
    }

    const generatePDF = async () => {
        try {
            setLoaderPDF(true)
            const result = await generatePdf({
                "name": nombre_reporte,
                "header": columns.map((column: any) => {
                    return {
                        header: column.title,
                        key: column.dataIndex,
                        width: 40
                    }
                }),
                userName: usuario.usuario,
                // limit: LIMITE_PDF,
                ...props.filterTabla
            })

            let columnsPDF = result.data.data.header.map((column: any) => {
                return {
                    header: column.header,
                    dataKey: column.key
                }
            })


            const { data: dataTablePdf } = result.data.data

            const parseData = dataTablePdf.map((data: any) => {
                return {
                    ...data,
                    precio: `$${setearMiles(data.precio)}`,
                    valor_descuento_total: `$${setearMiles(data.valor_descuento_total)}`,
                    precio_diferencial: `$${setearMiles(data.precio_diferencial)}`,
                    valor_venta_total: `$${setearMiles(data.valor_venta_total)}`

                }
            })

            console.log("result.data.pdf =>>", result.data.data)

            const document: any = new jsPDF('l', 'pt', [5000, 1000], true)
            document.setFontSize(14)
            document.setFont('helvetica', 'italic')
            document.autoTable({
                startY: 50,
                body: parseData,
                columns: columnsPDF,
                styles: { fontSize: 20 },
                headStyles: { fillColor: [186, 0, 0], halign: 'center' },
                bodyStyles: { halign: 'center' }
            })
            document.save(nombre_reporte + '.pdf')

            notification.info({ message: 'Generando archivo', description: `Estamos generando el archivo PDF, le notificaremos cuando este listo.`, placement: 'bottomRight', duration: 5 })
        } catch (error) {
            notification.error({ message: 'Ocurrió un error', description: 'Por favor intente nuevamente', placement: 'bottomRight', duration: 5 })
        } finally {
            setLoaderPDF(false)
        }
    }

    const generateLocalPDF = () => {
        try {
            if (data.length > 0) {
                const columnas = columns.map((column: any) => {
                    return {
                        header: column.title,
                        dataKey: column.dataIndex
                    }
                })

                const parseData = data.map(data => {
                    return {
                        ...data,
                        precio: `$${setearMiles(data.precio)}`,
                        cantidad: `${setearMiles(data.cantidad)}`,
                        valor_descuento_total: `$${setearMiles(data.valor_descuento_total)}`,
                        valor_venta_total: `$${setearMiles(data.valor_venta_total)}`,
                        subtotal_venta: `$${setearMiles(data.subtotal_venta)}`,
                        recaudo: `$${setearMiles(data.recaudo)}`,
                        total: `$${setearMiles(data.total)}`
                    }
                })
                exportTablePDF(parseData, columnas, props.tituloReporte ?? 'Reporte ventas consolidado')
            } else {
                notification.warning({ message: 'Notificación', description: `${usuario.usuario}, No se encuentran datos para exportar`, placement: 'bottomRight', duration: 5 })
            }
        } catch (error) {
            console.log('ERROR:::', error)
        }
    }

    const generateExcelTable = async () => {
        try {
            setLoaderExcel(true)

            const result = await generateExcel(({
                "name": nombre_reporte,
                "header": columns.map((column: any) => {
                    return {
                        header: column.title,
                        key: column.dataIndex,
                        width: 40
                    }
                }),
                userName: usuario.usuario,
                ...props.filterTabla
            }), props.genExcelUrl)






            const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(result.data.data);
            console.log('worksheet', worksheet);
            const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            const data: Blob = new Blob([excelBuffer], {
                type: EXCEL_TYPE
            });
            saveAs(data, nombre_reporte + ".xlsx");

            notification.info({ message: 'Generando archivo', description: 'Estamos generando el archivo excel, le notificaremos cuando este listo', placement: 'bottomRight', duration: 5 })
        } catch (error) {
            notification.error({ message: 'Ocurrió un error', description: 'Por favor intente nuevamente', placement: 'bottomRight', duration: 5 })
        } finally {
            setLoaderExcel(false)
        }
    }

    const handleOnChange = (event: any) => {
        let { value } = event.target;
        setSearchText(value);
    }

    const handlePagination = (pagination: any, _filter: any, sorter: any) => {
        const { column, order } = sorter

        if (column && order) {
            setSort({
                [column.dataIndex]: order == "ascend" ? 1 : -1
            })
        } else {
            setSort({})
        }
        setCurrentPage(pagination.current);
        setPageSize(pagination.pageSize);
    };

    useEffect(() => {
        if (sort) {
            setTotal(true)
            setFilterDefaultValues({
                ...filterDefaultValues,
                sort
            })
        }
    }, [sort])
    useEffect(() => {
        if (currentPage || pageSize) {
            setTotal(true)
            setFilterDefaultValues({
                ...filterDefaultValues,
                limit: pageSize,
                skip: (currentPage - 1) * pageSize,
            })
        }
    }, [currentPage, pageSize])
    useEffect(() => {
        if (pageSize && props.update != null) {
            setPagination({
                ...pagination,
                current: 1,
                pageSize
            })
        }
    }, [pageSize])
    useEffect(() => {
        if (currentPage) {
            setPagination({
                ...pagination,
                current: currentPage,
                pageSize
            })
        }
    }, [currentPage])
    useEffect(() => {
        if ((!objetoVacio(filterDefaultValues) && props.update == null) || total) {
            fetchData()
        }
    }, [filterDefaultValues, total]);

    useEffect(() => {
        setSearchText('')
        if (props.update != null) {
            delete filterDefaultValues?.[searchKey]
            fetchData(true)
        }
    }, [props.update])

    useEffect(() => {
        setData([])
    }, [props.apiUrl])

    useEffect(() => {
        setData([])
        setFilterDefaultValues({})
    }, [])

    return (
        <div>
            <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'start', gap: '8px' }}>
                <Input.Search
                    id='btn-buscar'
                    data-cy='btn-buscar'
                    data-testid='btn-buscar'
                    placeholder="Buscar"
                    onChange={handleOnChange}
                    onSearch={handleSearch}
                    style={{ width: '25%' }}
                    value={searchText}
                />
                <Button id='btn-descargar-excel' data-cy={'btn-descargar-excel'} data-testid={'btn-descargar-excel'} loading={loaderExcel} type='default' >
                    <img src={excelIcon} onClick={generateExcelTable} alt="excelIcon" />
                </Button>
                <Button loading={loaderPDF} type='default' data-testid={'btn-descargar-pdf'} onClick={() => {
                    exportPDFLocal ? generateLocalPDF() : generatePDF()
                }} >
                    <img src={PdfIcon} alt="excelIcon" />
                </Button>
            </div>

            <Table
                id='tabla-ventas'
                data-cy='tabla-ventas'
                data-testid="pagination"
                className='smart-table'
                dataSource={data}
                columns={columns}
                title={header}
                loading={loading}
                pagination={pagination}
                onChange={handlePagination}
                scroll={{ x: true }}
            />
        </div>
    );
};


export default SmartDataTable
