import { Paper } from '@material-ui/core'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import Swal from 'sweetalert2'
import server from 'util/Server';
import ContainerHeader from 'components/ContainerHeader/index'
import LoaderTerpel from 'components/LoaderTerpel/LoaderTerpel'
import { REPORTE_RECAUDO_VENTAS } from 'constants/columnas/columnasReportes'
import { formatearFecha, formatosFecha } from 'util/formatDate'
import Storage from 'util/store/Storage'
import { getSelectEDS } from '../servicios/serviciosSelect'
import SmartDataTable from 'components/SmartDataTable/SmartDataTable'
import { getRegionalService } from 'services/selectServices/services';
import FiltrosFC from 'components/FiltrosFC/FiltrosFC';
import { App } from 'antd';


const ReporteRecaudoVentas = (props: PropsComponent) => {
  const { notification } = App.useApp()
  const { empresasHijos, usuario, negocio } = Storage.getState()
  const filtroRef: any = useRef(null)
  const PREFIX_URL = `${server.host}${server.puerto_reporte_service}/api/recaudos`
  const URL_REPORTE_RECAUDOS = PREFIX_URL
  const URL_DOC_REPORTE = `${PREFIX_URL}/documento-reporte`
  const [loaderNew, setLoaderNew] = useState(false)
  const [loader, setLoader] = useState(false)
  const [loadingTotal, setLoadingTotal] = useState(false)
  const [URL_SERVICES, setURL_SERVICES] = useState(URL_REPORTE_RECAUDOS)
  const [COLUMNS, setCOLUMNS] = useState(REPORTE_RECAUDO_VENTAS)
  const [update, setUpdate] = useState<boolean | null>(null)
  const [nombreReporte, setNombreReporte] = useState('Reporte_ventas_gnv_')
  const [filtrosTabla, setFiltrosTabla] = useState({} as any)
  const [filtros, setFiltros] = useState({
    selectedDate: null,
    selectedDateFin: null,
    estacionesbd: [],
    empresaSeleccionada: usuario.tipo == 5 ? usuario.empresas_id : null,
    opcionSeleccionada: null
  })

  const handleLoading = (value: boolean) => {
    setLoaderNew(value)
  }

  const handleSetFiltros = (name: string) => (valor: any): void => {
    setFiltros((prev) => ({ ...prev, [name]: valor }))
  }

  const obtenerDatos = useCallback(async (): Promise<void> => {
    const estacionesbd = await getSelectEDS(empresasHijos)
    setFiltros(prevF => ({ ...prevF, estacionesbd }))
  }, [empresasHijos])


  const nullUpdate = () => {
    setUpdate(null)
  }
  const buscarDatosFiltrado = async () => {
    try {
      const { empresaSeleccionada, selectedDate, selectedDateFin } = filtros
      if (!selectedDate || !selectedDateFin) return notification.info({
        message: 'Atención',
        description: 'Debe seleccionar un rango de fechas',
        placement: 'top'
      })

      if (!empresaSeleccionada) return notification.info({
        message: 'Atención',
        description: `Debe seleccionar una estación`,
        placement: 'top'
      })

      setLoadingTotal(true)
      setLoader(true)
      const datos = {
        fecha_final: formatearFecha(selectedDateFin, formatosFecha.fechaServidor),
        fecha_inicial: formatearFecha(selectedDate, formatosFecha.fechaServidor),
        empresa_id: empresaSeleccionada,
        identificadorEstacion: empresaSeleccionada
      }
      setFiltrosTabla(datos)
      setUpdate(true)
    } catch (error) {
      console.log(error)
      Swal.fire('Ups', 'Ocurrió un error al consultar el reporte, por favor intente más tarde', 'error')
    } finally {
      setLoadingTotal(false)
      setLoader(false)
    }
  }


  const RenderDataTable2 = () => {
    return (
      <Paper>
        <SmartDataTable
          exportPDFLocal={true}
          columns={COLUMNS}
          hanldeLoading={handleLoading}
          apiUrl={URL_SERVICES}
          filterTabla={filtrosTabla}
          nullUpdate={nullUpdate}
          update={update}
          nombre_reporte={nombreReporte}
          tituloReporte={nombreReporte.replaceAll('_', ' ')}
          genExcelUrl={`${URL_DOC_REPORTE}?tipo=EXCEL`}
        />
      </Paper>
    )
  }
  /**
   * @Description: Funcion que consume un endpoint
   * @input {String}
          * @return {String}
          * @
          */


  useEffect(() => {
    if (usuario.tipo != 5) {
      
      setFiltros(prevF => ({ ...prevF, empresaSeleccionada: null }))
      filtroRef?.current?.resetSelectB()
      setFiltrosTabla({})

      obtenerDatos()

    }

  }, [obtenerDatos, filtros.opcionSeleccionada])

  return (
    <div className="dashboard animated slideInUpTiny animation-duration-3">
      <ContainerHeader match={props.match} title={'REPORTE DE VENTAS GNV'} />

      <FiltrosFC
        ref={filtroRef}
        loadingButton={(loaderNew || loadingTotal)}
        rangePicker
        selectB
        formatDateRange='YYYY-MM-DD'
        onChangeSelectB={handleSetFiltros('empresaSeleccionada')}
        optionsSelectB={filtros.estacionesbd}
        placeholderSelectB={'Estaciones'}
        onChangeRangePicker={(e) => {
          handleSetFiltros('selectedDate')(e[0])
          handleSetFiltros('selectedDateFin')(e[1])
        }}
        onClickButton={buscarDatosFiltrado}
        textButton='Buscar'
      />
      {loader ? (
        <LoaderTerpel />
      ) :
        RenderDataTable2()
      }
    </div>
  )
}

export default ReporteRecaudoVentas
