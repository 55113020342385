import Loader from 'components/Loader'
import React, { lazy, Suspense, useEffect } from 'react'
import { Route, Switch } from 'react-router-dom'

const FormClientProviderGnv = lazy(async () => import('./RegistroClienteGnv'))
const GestionVehiculosGnv = lazy(async () => import('./GestionVehiculosGnv'))
const GestionRecaudosGnv = lazy(async () => import('./GestionRecaudosGnv'))
//rutas

const Gnv = ({ match }: any) => {

  useEffect(() => {
    console.log(match.url)
  }, [])

  return (
    <div className="app-wrapper">
      <Switch>
        <Suspense fallback={<Loader />}>
          <Route path={`${match.url}/clientes_gnv`} component={FormClientProviderGnv} />
          <Route path={`${match.url}/vehiculos_gnv`} component={GestionVehiculosGnv} />
          <Route path={`${match.url}/registro_recaudos_clientes`} component={GestionRecaudosGnv} />
        </Suspense>
      </Switch>
    </div>
  )
}

export default Gnv
