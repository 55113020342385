//Funcion para setear el precio
import Axios from 'axios'
import server from 'util/Server'
import swal from 'sweetalert'
import Storage from 'util/store/Storage'
import moment from 'moment'
import dayjs from 'dayjs'
import { notification } from 'antd'
import jsPDF from "jspdf";
import { applyPlugin } from 'jspdf-autotable'
applyPlugin(jsPDF)


export function setearMiles(numero, cantidadDecimales=2) {
    if (numero === null || numero === undefined || numero === 'NaN' || isNaN(numero)) {
        return '0'
    } else {
        const { usuario } = Storage.getState()
        let moneda = 'COP'
        try {
            moneda = usuario.dominio.parametros.flag
        } catch (error) {}
        switch (moneda) {
            case 'COP':
                return new Intl.NumberFormat('de-DE').format(parseFloat(numero).toFixed(cantidadDecimales))
            case 'mx':
                return new Intl.NumberFormat('es-MX').format(parseFloat(numero).toFixed(cantidadDecimales))
            case 'us':
                return new Intl.NumberFormat('en-IN').format(parseFloat(numero).toFixed(cantidadDecimales))
            default:
                return new Intl.NumberFormat('de-DE').format(parseFloat(numero).toFixed(cantidadDecimales))
        }
    }
}

export const switchToCop = number => {
    if (number === null || number === undefined) {
        return '0'
    } else {
        return new Intl.NumberFormat('es-CO').format(parseFloat(number).toFixed(3))
    }
}

export function sessionCaducada() {
    let Str = ''
    let dCompleto = window.location.href
    dCompleto = dCompleto
        .replace('https://', '')
        .replace('http://', '')
        .replace(':3000', '')
        .replace('//', '/')
    let dsplit = dCompleto.split('/')
    if (dsplit[1] === 'app') {
        Str = '/'
    } else {
        if (dsplit.length > 1 && dsplit[1] !== 'app' && dsplit[1] !== '') {
            Str = '/' + dsplit[1] + '/'
        } else {
            Str = '/'
        }
    }
    notification.info({message:'Su sesión ha caducado',description:'Debido a la inactividad, hemos cerrado su sesión'})
    setTimeout(function() {
        window.location.href = Str
    }, 3000)
}

export function obtenerValoresArray(array) {
    let valores = array.map(valor => valor.value).join(',')
    return valores
}

export function replaceAll(inputString, stringToReplace, stringThatReplace) {
    while (inputString.indexOf(stringToReplace) > -1) {
        inputString = inputString.replace(stringToReplace, stringThatReplace)
    }

    return inputString
}

export function obtenerImpuestoTotal(cantidad, precio, impuestos) {
    let impuestoTotal = 0
    if (!impuestos) {
        return impuestoTotal
    }
    impuestos.forEach(impu => {
        const precioTotal = cantidad * precio
        if (impu.porcentaje_valor === '%') {
            impuestoTotal += (parseFloat(precioTotal) * parseFloat(impu.impuesto_valor)) / 100
        }
    })
    impuestos.forEach(impu => {
        if (impu.porcentaje_valor === '$') {
            impuestoTotal += cantidad * parseFloat(impu.impuesto_valor)
        }
    })
    return parseFloat(impuestoTotal)
}

export function setearImpuesto(unidades_contenido_valor, costo_producto, impuestos, cantidad) {
    let imp = 0
    impuestos.forEach(impu => {
        let coston = 0
        coston = cantidad
        coston = coston * costo_producto
        if (impu.porcentaje_valor === '%') {
            imp += (parseFloat(coston) * parseFloat(impu.impuesto_valor)) / 100
        }
        if (impu.porcentaje_valor === '$') {
            imp += parseFloat(cantidad) * parseFloat(unidades_contenido_valor) * parseFloat(impu.impuesto_valor)
        }
    })
    return imp
}
export function calcularPorcentaje(datosArr) {
    let total = 0
    let porcentaje = []
    datosArr.forEach(dat => {
        total += parseInt(dat)
    })

    datosArr.forEach(dat => {
        porcentaje.push((parseInt(dat) * 100) / total)
    })

    return porcentaje
}

export function permisosModulos(props) {
    let asignaciones = ''
    try {
        const enlace = props.match.url

        let partes = enlace.split('/')
        let enlaceFinal = ''
        if (partes.length === 5) {
            enlaceFinal = '/' + partes[2] + '/' + partes[3] + '/' + partes[4]
        } else if (partes.length === 4) {
            enlaceFinal = '/' + partes[1] + '/' + partes[2] + '/' + partes[3]
        } else {
            enlaceFinal = '/' + partes[0] + '/' + partes[1] + '/' + partes[2]
        }
        const { usuario } = Storage.getState()
        if (Array.isArray(usuario.modulos)) {
            usuario.modulos.forEach(modulo => {
                if (modulo.link === enlaceFinal) {
                    asignaciones = modulo.asignaciones
                }
            })
        }
    } catch (error) {}
    return asignaciones
}

export const esUsuarioFinanciera = (tipo, atributos) => {
    try {
        switch (tipo) {
            case 3:
                return atributos.tipo_negocio === 'F'
            default:
                return atributos.tipo_unidad === 'F'
        }
    } catch (error) {
        return false
    }
}

export const valorParametro = (parametro, empresa) => {
    try {
        return empresa.parametros[parametro] || 'N'
    } catch (error) {
        return 'N'
    }
}

export const createPDF = (nombre, html) => {
    const { usuario, dominio } = Storage.getState()
    const headers = {
        Authorization: server.obtenerToken(),
        versionapp: server.versionApp,
        versioncode: server.versionCode,
        fecha: moment().format(),
        aplicacion: server.aplicacion,
        identificadordispositivo: server.aplicacion
    }
    const datos = {
        template: html,
        filename: nombre + '.pdf',
        dominio: dominio,
        entidad: 'movimiento',
        empresas_id: parseInt(usuario.empresas_id)
    }
    Axios.post(server.host + server.puertoCentury + '/api/pdf/download', datos, { headers: headers }).then(response => {
        if (response.status === 201) {
            const link = document.createElement('a')
            link.setAttribute('target', '_blank')
            link.download = response.data.data.filename
            link.href = response.data.data.url
            link.click()
        }
    })
}

export const arraysEquals = (array1, array2) => {
    return array1.toString() === array2.toString()
}

export const getColorCombustible = tipo_combustible => {
    let color = ''
    switch (tipo_combustible) {
        case 'CORRIENTE':
            color = '#E50000'
            break
        case 'DIESEL':
            color = '#FFE000'
            break
        case 'EXTRA':
            color = '#0013F9'
            break
        case 'GAS':
            color = '#0EC100'
            break
        case 'GLP':
            color = '#939393'
            break
        case 'ADBLUE':
            color = '#3E9AFF'
            break
        default:
            break
    }
    return color
}

const fechas = {
    DATE_VISTA: 'DD/MM/YYYY',
    DATETIME_VISTA: 'DD/MM/YYYY hh:mm a',
    timezone: 'YYYY-MM-DDTHH:mm:ss',
    sinTimezone: 'YYYY-MM-DD HH:mm:ss',
    TIME_VISTA: 'hh:mm a'
}

export const estilizar_objeto = objeto => {
    const objeto_Estilizado = JSON.parse(
        JSON.stringify(objeto, function(key, value) {
            if (key.includes('_peso')) {
                return '$ ' + setearMiles(value)
            } else if (key.includes('_formateado')) {
                return setearMiles(value)
            } else if (key.includes('_datetime')) {
                return moment(value, resolveTimeZone(value)).format(fechas.DATETIME_VISTA)
            } else if (key.includes('_time')) {
                return moment(value).format(fechas.TIME_VISTA)
            } else if (key.includes('_date')) {
                return moment(value).format(fechas.DATE_VISTA)
            } else {
                return value
            }
        })
    )
    return objeto_Estilizado
}

const resolveTimeZone = fecha => {
    if (fecha === null) return 'N/A'
    if (fecha.includes('T')) {
        return fechas.timezone
    } else {
        return fechas.sinTimezone
    }
}

export const esUsuarioAdministrador = usuario => {
    const perfiles = usuario.perfiles.map(perfil => perfil.descripcion)
    return (
        perfiles.includes('ADMINISTRADOR DE ESTACION') ||
        perfiles.includes('ADMINISTRADOR DE EDS BETA 4') ||
        perfiles.includes('ADMINISTRADOR ESTACION') ||
        perfiles.includes('ADMINISTRADOR DE EDS BETA 3')
    )
}

export const obtenerPermisosModulos = (usuario, nombreModulo) => {
    try {
        const modulo = usuario.modulos.find(modulo => modulo.descripcion == nombreModulo.toUpperCase())
        if (!modulo) return ''
        return modulo.asignaciones
    } catch (error) {
        console.log(error)
        return ''
    }
}
export const obtenerPermisosByPath = (usuario, pathname) => {
  // console.log(pathname)
  // console.log(usuario)
  try {
    const modulo = usuario.modulos?.find(module => {
      if (pathname == "/app/dashboard/") {
        return { ...module };
      } else {
        return module.link == pathname;
      }
    })
    if (!modulo) return ''
    return modulo.asignaciones
  } catch (error) {
    console.log(error)
    return ''
  }
}
export const validarNumero = (rule, value, callback) => {
    if (isNaN(value)) {
        return Promise.reject('El valor ingresado debe ser un número.')
    } else {
       return Promise.resolve()
    }
}
export const validar0Firts = (rule, value, callback) => {
    if (value && `${value}`.trim()[0] == '0') {
        return Promise.reject('No es permitido el 0 a la izquierda')
    } else {
       return Promise.resolve()
    }
}
export const validateRange = (rule, value, callback, range) => {
    if(value < range[0] || value > range[1]) {
        return Promise.reject(`El valor ingresado debe estar entre ${range[0]} y ${range[1]}`)

    }else{
        return Promise.resolve()
    }
}
export const validarNumeroPositivo = (rule, value, callback) => {
    if (isNaN(value)) {
        callback('El valor ingresado debe ser un número.')
    } else if(Number(value)<0) {
        callback('No puede ingresar un valor negativo')
    }else {
        callback()
    }
}

export const validarLongitudVacia = (rule, value, callback) => {
    if (`${value}`.length>0 && `${value}`.trim().length < 1) {
        return Promise.reject('Valor ingresado no valido')
    } else {
       return Promise.resolve()
    }
}
export const validarLongitud4 = (rule, value, callback) => {
    if (`${value}`.trim().length < 4) {
        return Promise.reject('Valor ingresado no valido')
    } else {
       return Promise.resolve()
    }
}
export const validarLongitud3 = (rule, value, callback) => {
    if (`${value}`.trim().length > 3) {
        return Promise.reject('Valor ingresado no valido')
    } else {
       return Promise.resolve()
    }
}
export const validarLongitud5 = (rule, value, callback) => {
    if (`${value}`.trim().length < 5) {
        return Promise.reject('Valor ingresado no valido')
    } else {
       return Promise.resolve()
    }
}
export const validarLongitudMin5Max10= (rule, value, callback) => {
    if (`${value}`.trim().length < 5 || `${value}`.trim().length > 10) {
        return Promise.reject('Valor ingresado no valido')
    } else {
       return Promise.resolve()
    }
}
export const validarLongitudMin7Max20= (rule, value, callback) => {
    if (`${value}`.trim().length < 7 || `${value}`.trim().length > 20) {
        return Promise.reject('Valor ingresado no valido')
    } else {
       return Promise.resolve()
    }
}
export const validarLongitudMin5Max30= (rule, value, callback) => {
    if (`${value}`.trim().length < 5 || `${value}`.trim().length > 30) {
        return Promise.reject('Valor ingresado no valido')
    } else {
       return Promise.resolve()
    }
}
export const validarMinLongitud5 = (rule, value, callback) => {
    if (`${value}`.trim().length > 5) {
        return Promise.reject('Valor ingresado no valido')
    } else {
       return Promise.resolve()
    }
}
export const validarMaxLongitud10 = (rule, value, callback) => {
    if (`${value}`.trim().length > 10) {
        return Promise.reject('Valor ingresado no valido')
    } else {
       return Promise.resolve()
    }
}
export const validarMaxLongitud30 = (rule, value, callback) => {
    if (`${value}`.trim().length > 30) {
        return Promise.reject('Valor ingresado no valido')
    } else {
       return Promise.resolve()
    }
}
export const validarMaxLongitud11 = (rule, value, callback) => {
    if (`${value}`.trim().length > 11) {
        return Promise.reject('Valor ingresado no valido')
    } else {
       return Promise.resolve()
    }
}
export const validarMaxLongitud15 = (rule, value, callback) => {
    if ( `${value}`.trim().length > 16 || value == '0' ) {
        return Promise.reject('Valor ingresado no valido')
    } else {
       return Promise.resolve()
    }
}
export const validarMaxLongitud16 = (rule, value, callback) => {
    if (`${value}`.trim().length < 6 || `${value}`.trim().length > 16 || value == '0' ) {
        callback('Valor ingresado no valido')
    } else {
        callback()
    }
}
export const validarLongitud6 = (rule, value, callback) => {
    if (`${value}`.trim().length < 6) {
        return Promise.reject('Valor ingresado no valido')
    } else {
       return Promise.resolve()
    }
}

export const debounce = (callback, wait) => {
    let interval
    return (...args) => {
        interval = setTimeout(() => {
            interval = null
            callback(...args)
            clearTimeout(interval)
        }, wait)
    }
}

export function objectsAreEqual(obj1, obj2) {
    console.log(obj1, obj2)
    const props1 = Object.getOwnPropertyNames(obj1)
    const props2 = Object.getOwnPropertyNames(obj2)

    if (props1.length !== props2.length) {
        return false
    }

    for (const propName of props1) {
        if (obj1[propName] !== obj2[propName]) {
            return false
        }
    }

    return true
}

export const removeTotalPaginator = () => {
    let el = document.querySelector('.ant-pagination-simple-pager')
    let children = el.childNodes
    let new_children = []
    children.forEach(it => {
        if (it.nodeName != '#text') {
            new_children.push(it)
        } else {
            el.removeChild(it)
        }
    })
    new_children.forEach(it => {
        el.appendChild(it)
    })
}

export const diferenciaEntreDosFechas = (dateStr1, dateStr2, n) => {
    const date1 = dayjs(dateStr1, 'YYYY-MM-DD')
    const date2 = dayjs(dateStr2, 'YYYY-MM-DD')
    const diffInDays = date2.diff(date1, 'day')
    return Math.abs(diffInDays) >= n
}

export function sonMismaFecha(date1, date2) {
    // Obtener los valores de año, mes y día para cada fecha
    const year1 = date1.getFullYear();
    const month1 = date1.getMonth();
    const day1 = date1.getDate();

    const year2 = date2.getFullYear();
    const month2 = date2.getMonth();
    const day2 = date2.getDate();

    // Comparar si los valores son iguales
    return year1 === year2 && month1 === month2 && day1 === day2;

}

export function generatePDF(dataArray,name) {
    // Crear un nuevo documento jsPDF
    const doc = new jsPDF('l', 'pt', [900,1500]);

    // Encabezado de la tabla
    const headers = Object.keys(dataArray[0]);

    // Filas de la tabla
    const rows = dataArray.map((obj) => Object.values(obj));

    // Pintar la tabla en el documento
    doc.autoTable({
      head: [headers],
      body: rows,
      headStyles: { fillColor: [145 , 145, 145] }
    });

    // Guardar el documento en un archivo PDF
    doc.save(`${name}.pdf`);
  }

  export const validateEmail = (rule, value, callback) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!value || emailRegex.test(value)) {
      callback(); // Válido
    } else {
      callback('Por favor, ingrese una dirección de correo electrónico válida.');
    }
  };

  export const validateNit = (rule, value, callback) => {
    const nitRegex = /^\d{10}-[1-9]|10$/;
    if (nitRegex.test(value)) {
      callback(); // Válido
    } else {
      callback('Por favor, ingrese un NIT valido');
    }
  };


  export function sonObjetosIguales(objetos) {
    if (objetos.length < 2) {
      // No se pueden comparar menos de dos objetos
      return true;
    }

    const primerObjeto = objetos[0];

    for (let i = 1; i < objetos.length; i++) {
      const objetoActual = objetos[i];

      const clavesPrimerObjeto = Object.keys(primerObjeto);
      const clavesObjetoActual = Object.keys(objetoActual);

      if (clavesPrimerObjeto.length !== clavesObjetoActual.length) {
        return false;
      }

      for (const clave of clavesPrimerObjeto) {
        if (!clavesObjetoActual.includes(clave)) {
          return false;
        }

        const valorPrimerObjeto = primerObjeto[clave];
        const valorObjetoActual = objetoActual[clave];

        if (typeof valorPrimerObjeto === 'object' && typeof valorObjetoActual === 'object') {
          if (!sonObjetosIguales([valorPrimerObjeto, valorObjetoActual])) {
            return false;
          }
        } else if (valorPrimerObjeto !== valorObjetoActual) {
          return false;
        }
      }
    }

    return true;
  }


  export function esDecimal(valor) {
    // Verificar si el valor es un número o puede convertirse a un número
    if (isNaN(valor)) {
      return false;
    }

    // Convertir el valor a un número si es un string
    const numero = typeof valor === 'string' ? parseFloat(valor) : valor;

    // Verificar si el número tiene decimales
    return numero % 1 !== 0;
  }

  export const esPar = numero => numero % 2 === 0
