import Axios from 'axios';
import Servidor from 'util/Servidor';
import { getColorCombustible, sessionCaducada } from 'util/funciones'
import moment from 'moment';

export const getChartsDataMongo = async (body: any): Promise<any[]> => {
    return new Promise(function (resolve, reject) {
        Axios.post(`${Servidor.host}${Servidor.puerto_reporte_service}/api/dashboard`,
            body,
            { headers: {} }
        )
            .then((res) => {
                if (res.status === Servidor.responseOK) {
                    resolve(res.data.data);
                } else if (res.status === Servidor.responseSesionCaducada) {
                    sessionCaducada()
                }
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });

};

export const getChartsData = async (chart: string, body: any): Promise<any[]> => {
    return new Promise(function (resolve, reject) {
        Axios.post(`${Servidor.host}${Servidor.puertoMovimiento}/api/reportes/dashboard-lite/${chart}`,
            body,
            { headers: {} }
        )
            .then((res) => {
                if (res.status === Servidor.responseOK) {
                    resolve(res.data.data);
                } else if (res.status === Servidor.responseSesionCaducada) {
                    sessionCaducada()
                }
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });

};
export const getChartProductsMostSales = async (body: any): Promise<any[]> => {
    return new Promise(function (resolve, reject) {
        Axios.post(`${Servidor.host}${Servidor.puerto_reporte_service}/api/dashboard/products-sales`,
            body,
            { headers: {} }
        )
            .then((res) => {
                if (res.status === Servidor.responseOK) {
                    resolve(res.data.data);
                } else if (res.status === Servidor.responseSesionCaducada) {
                    sessionCaducada()
                }
            })
            .catch((err) => {
                console.error(err);
                reject(err);
            });
    });

};

export const getGraficaCombinada = async (data: any): Promise<any> => {

    const getIslas = (data: any) => {
        let islas: any[] = []
        let islasIds: any[] = []
        let totalIsla: any[] = []
        let surtidores: any[] = []
        // eslint-disable-next-line
        let totalProductos = 0
        let arrayTorta: any[] = []
        if (data != null && data.lenght !== 0) {
            data.cantidadIsla.forEach((det: any) => {
                islas.push("ISLA" + det.isla)
                islasIds.push(det.isla)
                totalIsla.push(0)
            })
            data.dataSurtidores.forEach((surt: any) => {
                if (!surtidores[(parseInt(surt.surtidor) - 1)]) {
                    surtidores[(parseInt(surt.surtidor) - 1)] = {
                        type: "column",
                        name: "SURTIDOR " + surt.surtidor,
                        data: [],
                    }
                    totalIsla[islasIds.indexOf(surt.isla)] += parseFloat(surt.total_venta)
                    surtidores[(parseInt(surt.surtidor) - 1)].data[islasIds.indexOf(surt.isla)] = parseFloat(surt.total_venta)
                } else {
                    totalIsla[islasIds.indexOf(surt.isla)] += parseFloat(surt.total_venta)
                    surtidores[(parseInt(surt.surtidor) - 1)].data[islasIds.indexOf(surt.isla)] = parseFloat(surt.total_venta)
                }
            })
            data.ProductosTorta.forEach((torta: any) => {
                totalProductos += parseFloat(torta.total_venta)
            })
            data.ProductosTorta.forEach((torta: any) => {
                arrayTorta.push({
                    name: torta.descripcion,
                    y: parseFloat(torta.total_venta),
                    color: getColorCombustible((torta.descripcion + "").toUpperCase()), // Jane's color
                })
            })
        }
        surtidores.forEach((surtidor, k1) => {
            for (let index = 0; index < surtidor.data.length; index++) {
                if (typeof surtidor.data[index] === "undefined") {
                    surtidores[k1].data[index] = 0
                }
            }
        })
        surtidores.push({
            type: "spline",
            name: "TOTAL DE VENTAS POR ISLA",
            data: totalIsla,
            marker: {
                lineWidth: 2,
                lineColor: "#000",
                fillColor: "white",
            },
        })
        surtidores.push({
            type: "pie",
            name: "VENTAS POR PRODUCTO",
            data: arrayTorta,
            center: [100, 80],
            size: 100,
            showInLegend: false,
            dataLabels: {
                enabled: true,
            },
        })
        return ({
            islas,
            surtidores
        })
    };

    return new Promise(function (resolve, reject) {
        Axios.post(
            Servidor.host + Servidor.puertoMovimiento + "/api/reportes/dashboard-lite/combinado",
            data,
            {
                headers: {
                    Authorization: Servidor.obtenerToken(),
                    versionapp: Servidor.versionApp,
                    versioncode: Servidor.versionCode,
                    fecha: moment().format(),
                    aplicacion: Servidor.aplicacion as string,
                    identificadordispositivo: Servidor.aplicacion as string,
                }
            }
        )
            .then((res) => {
                if (res.status === Servidor.responseOK) {
                    const data = getIslas(res.data.data)
                    resolve(data)
                } else if (res.status === Servidor.responseSesionCaducada) {
                    sessionCaducada()
                }
            })
            .catch((error) => {
                reject(error)
            });
    })
}

